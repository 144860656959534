import React, { useState, useEffect } from 'react'
import './styling/OverviewContainer.css'
import ListHeader from '../components/ListHeader'
import ListBody from '../components/ListBody'
import GridListContainer from '../components/GridListContainer'
import OverviewToolbar from '../components/OverviewToolbar'
import MediaFileTreeContainer from '../components/MediaFileTreeContainer'
import DevNotesModal from '../modals/DevNotesModal'
import { menuOptions, sqclistHeaders, sqclistKeys, fqclistHeaders, fqclistKeys } from '../DashboardConstants'

function OverviewContainer({ qcDashboardActive, pageDataLoading, currentMenuOption, setSelectListItem, selectedListItemData, selectedListData, searchTerm, setsearchTerm,
  toggleModal, toggleAlert, selectMenuOption, readDataDB, editButtonClicked, startFileDownload, jobMetaData, dismissRecentRecords, logout, changeActiveDashboard,
  topSpinnerIsLoading, generateButtonClicked, updatedCheckSocketData, newJobAlertShow, setnewJobAlertShow }) {

  const [listData, setlistData] = useState([])
  const [currentListHeaders, setcurrentListHeaders] = useState([])
  const [currentListKeys, setcurrentListKeys] = useState([])
  const [activeListType, setactiveListType] = useState(""); //list / list-expandable / grid / grid-header
  const [numColumns, setnumColumns] = useState(0) //for UI display
  const [selectedFilterButton, setselectedFilterButton] = useState(-1) //For resource filtering
  const [displayCSVuploadButton, setdisplayCSVuploadButton] = useState(false);
  const [sort, setSort] = useState({ key: "", direction: "" }) //For sorting
  const [showDevNotes, setShowDevNotes] = useState(false)

  const listHeaders = qcDashboardActive === "sqc" ? { ...sqclistHeaders } : { ...fqclistHeaders } //Table header values
  const listKeys = qcDashboardActive === "sqc" ? { ...sqclistKeys } : { ...fqclistKeys }//Table column key values (get column from JSON)

  useEffect(() => { //init display data
    const data = selectedListData
    if (data === undefined) return
    setlistData(data)
  }, [selectedListData])

  useEffect(() => { //init table and actions
    const activeDisplayType = menuOptions[qcDashboardActive][currentMenuOption].displayType
    if (activeDisplayType.includes("list")) {
      // if (((qcDashboardActive === "sqc") && (currentMenuOption <= 2)) || ((qcDashboardActive === "fqc") && (currentMenuOption !== 4) && (currentMenuOption !== 6))) {
      setcurrentListHeaders(listHeaders[currentMenuOption])
      setcurrentListKeys(listKeys[currentMenuOption])
      setnumColumns(listHeaders[currentMenuOption].length)
    }
    if (qcDashboardActive === "sqc" && currentMenuOption === 3) {
      setdisplayCSVuploadButton(true)
    } else {
      displayCSVuploadButton && setdisplayCSVuploadButton(false)
    }
    setactiveListType(activeDisplayType)
  }, [currentMenuOption]) //maby remove 1st dep (currentMenuOption)

  function renderListContainer() {
    if (activeListType.includes("list")) {
      return (<>
        <ListHeader
          numColumns={numColumns}
          headerArray={currentListHeaders}
          setSort={setSort}
          sort={sort}
        />
        <ListBody
          activeListType={activeListType}
          pageDataLoading={pageDataLoading}
          currentMenuOption={currentMenuOption}
          setSelectListItem={setSelectListItem}
          selectedListItemData={selectedListItemData}
          numColumns={numColumns}
          listData={listData}
          searchTerm={searchTerm}
          keyArray={currentListKeys}
          toggleModal={toggleModal}
          editButtonClicked={editButtonClicked}
          startFileDownload={startFileDownload}
          jobMetaData={jobMetaData}
          dismissRecentRecords={dismissRecentRecords}
          sort={sort}
          generateButtonClicked={generateButtonClicked}
          qcDashboardActive={qcDashboardActive}
          updatedCheckSocketData={updatedCheckSocketData}
        />
      </>)
    } else if (activeListType.includes("grid")) {
      return (<GridListContainer
        activeListType={activeListType}
        pageDataLoading={pageDataLoading}
        setSelectListItem={setSelectListItem}
        listData={listData}
        searchTerm={searchTerm}
        currentMenuOption={currentMenuOption}
        toggleModal={toggleModal}
        selectedFilterButton={selectedFilterButton}
        editButtonClicked={editButtonClicked} />)
    } else {//media file tree
      return (<MediaFileTreeContainer
        qcDashboardActive={qcDashboardActive}
      />)
    }
  }

  const trigger = (qcDashboardActive === "sqc" && selectedListItemData?._id) ? 'collapse' : 'expand'//prevent pullup covering records making them inaccessible
  return (
    <div className='overview-container-body' trigger={trigger}>
      <OverviewToolbar
        setShowDevNotes={setShowDevNotes}
        searchTerm={searchTerm}
        activeListType={activeListType}
        displayCSVuploadButton={displayCSVuploadButton}
        setsearchTerm={setsearchTerm}
        selectedFilterButton={selectedFilterButton}
        setselectedFilterButton={setselectedFilterButton}
        toggleModal={toggleModal}
        currentMenuOption={currentMenuOption}
        selectMenuOption={selectMenuOption}
        toggleAlert={toggleAlert}
        readDataDB={readDataDB}
        jobMetaData={jobMetaData}
        logout={logout}
        changeActiveDashboard={changeActiveDashboard}
        topSpinnerIsLoading={topSpinnerIsLoading}
        qcDashboardActive={qcDashboardActive}
        newJobAlertShow={newJobAlertShow}
        setnewJobAlertShow={setnewJobAlertShow}
      />
      {showDevNotes &&
        <DevNotesModal setShowDevNotes={setShowDevNotes} />
      }
      {renderListContainer()}
    </div>
  )
}

export default OverviewContainer

